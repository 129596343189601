import Global from "./global";

export default class Stats {
  static TYPES = {
    REPORT_DATA_CHANGED: 'REPORT_DATA_CHANGED',
  };

  static reportDataChanged(rawData, processedData) {
    return {
      type: Stats.TYPES.REPORT_DATA_CHANGED,
      rawData,
      processedData
    }
  }

  static loadReport() {
    return statsRequest(
      "Pobieram dane do raportu",
      "Wystąpił błąd podczas pobierania danych do raportu.",
      {
        method: 'GET',
        url: `report`,
      }
    );
  }
}

function statsRequest(processingMessage, errorMessage, requestOptions) {
  return async (dispatch, getState) => {
    const {exercise, session, project} = getState();

    let projectId = project.currentProjectId;
    let sprintId = session.currentSessionId;
    let exerciseId = exercise.id;

    requestOptions['url'] =
      `admin/stats/`
      + `${requestOptions['url']}`
      + `/${projectId}/${sprintId}/${exerciseId}`;

    try {
      dispatch(Global.startProcessing(processingMessage));
      const {data, status} = await window.axios(requestOptions);
      if (status !== 200) {
        dispatch(Global.errorOccurred(errorMessage));
      } else {
        const rawData = data['data'];
        const processedData = data['processed_data'];
        dispatch(Stats.reportDataChanged(rawData, processedData));
        dispatch(Global.finishProcessing());
      }
    } catch(error) {
      dispatch(Global.errorOccurred(errorMessage));
    }
  }
}
