import DefaultInfo from "./_default";
import FIELD_TYPES from "../fieldTypes";
import {exerciseWithTime} from "./common/exercise";


class TilesInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...exerciseWithTime,
      {
        id: "answersHidden",
        description: "Ukrywać odpowiedzi?",
        type: FIELD_TYPES.bool,
        default: false,
      }
    ],
    modules: [
      "answer_categories"
    ]
  };

  static answer = {
    ...DefaultInfo.answer,
    modules: [
      "answer_categories"
    ]
  };

  static canAddAnswer = () => false;

  static newQuestion = () => (
    {
      content: "",
      parameters: {},
      answers: [
        this.newAnswer(true),
        this.newAnswer(),
        this.newAnswer(),
        this.newAnswer(),
      ]
    }
  );

  static newAnswer = (correct = false) => (
    {
      content: "",
      correct,
      parameters: {}
    }
  );
}

export default TilesInfo;