export default class Global {
  static TYPES = {
    ERROR_OCCURRED: 'ERROR_OCCURRED',
    START_PROCESSING: 'START_PROCESSING',
    FINISH_PROCESSING: 'FINISH_PROCESSING',
  };

  static errorOccurred(errorMessage) {
    return {
      type: Global.TYPES.ERROR_OCCURRED,
      message: errorMessage,
    }
  }

  static startProcessing(message) {
    return {
      type: Global.TYPES.START_PROCESSING,
      message,
    }
  }

  static finishProcessing() {
    return {
      type: Global.TYPES.FINISH_PROCESSING,
    }
  }
}