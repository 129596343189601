import React from 'react';
import {defaultTo} from 'lodash';

import BooleanInput from "components/ui/inputs/BooleanInput";
import InfoFactory from "exercises/ExerciseInfoFactory";
import Input from "components/ui/inputs/Input";

import "./AnswerParameters.scss";
import ModuleInfoFactory from "../../../exercises/ModuleInfoFactory";

export default function AnswerParameters({
  exerciseType,
  exerciseParameters,
  questionParameters,
  answer,

  setAnswerField$,
  setAnswerParameter$,
}) {
  const answerInfo = InfoFactory.getAnswerInfo(exerciseType);
  const parameters = answerInfo.parameters;
  const modules = defaultTo(answerInfo.modules, []).map((type) => (ModuleInfoFactory.getInfo(type).answerParameters)).flat();
  const otherValues = {
    exercise: exerciseParameters,
    question: questionParameters,
    answer: answer['parameters'],
  };

  return <>
    {(!answerInfo.hasNoCorrectIncorrect || answerInfo.parameters.length > 0) &&
    <div className="AnswerParameters">
      {!answerInfo.hasNoCorrectIncorrect &&
      <BooleanInput id="isCorrect"
        small
        value={answer.correct}
        defaultValue={false}
        setValue={setAnswerField$('correct')}
        labelChecked="Prawidłowa"
        labelUnchecked="Nieprawidłowa"
      />
      }
      {parameters.concat(modules).map((info, index) => (
        <Input
          key={index}
          info={info}
          otherValues={otherValues}

          value={answer.parameters[info.id]}
          setValue={setAnswerParameter$(info.id)}
        />
      ))}
    </div>
    }
  </>;
}