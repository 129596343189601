import DefaultInfo from "./_default";
import FIELD_TYPES from "../fieldTypes";


class MessageInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      {
        id: "header",
        description: "Nagłówek",
        placeholder: "Wpisz treść nagłówka",
        type: FIELD_TYPES.string,
        default: "",
      },
      {
        id: "content",
        description: "Treść",
        placeholder: "Wpisz treść głównej wiadomości",
        type: FIELD_TYPES.text,
        default: "",
      },
    ],
  };

  static canAddAnswer = () => false;
  static canAddQuestion = () => false;
}

export default MessageInfo;