import Stats from "../actions/stats";

const initialState = {
  rawData: [],
  processedData: [],
};

export default function stats(state = initialState, action={}) {
  switch(action.type) {
    case Stats.TYPES.REPORT_DATA_CHANGED:
      const {rawData, processedData} = action;
      return {
        ...state,
        rawData,
        processedData,
      };

    default:
      return state;
  }
};