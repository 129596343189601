import React from 'react';
import {Button, Card, Elevation, NonIdealState, Overlay} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import "./Overlay.scss";
import "./ErrorOverlay.scss";

export default function ErrorOverlay({isOpen, message}) {
  return <Overlay
    isOpen={isOpen}
    canEscapeKeyClose={false}
    canOutsideClickClose={false}
  >
    <div className="ErrorOverlay Overlay">
      <Card elevation={Elevation.THREE}>
        <NonIdealState
          icon={IconNames.ERROR}
          title="Błąd!"
          description={<div>
            <p>{message}</p>
            <p>Naciśnij przycisk poniżej, aby odświeżyć aplikację.</p>
          </div>}
          action={<Button large
            className="bp3-dark"
            icon={IconNames.REFRESH}
            text="Odśwież"
            onClick={() => window.location.reload()}
          />}
        />
      </Card>
    </div>
  </Overlay>
}