import {Button, ButtonGroup, FormGroup, InputGroup, Intent, TextArea} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import React, {useEffect, useState} from "react";
import validate from "validate.js";

validate.validators.unique_slug = function(value, options, key, attributes, globalOptions) {
  const {existingSlugs, currentSlug} = globalOptions;
  if (existingSlugs.includes(value) && currentSlug !== value) {
    return "Projekt o takiej uproszczonej nazwie już istnieje.";
  }
};

const validations = {
  name: {
    presence: {
      allowEmpty: false,
      message: "Musisz podać nazwę projektu",
    },
  },
  slug: {
    presence: {
      allowEmpty: false,
      message: "Musisz podać skróconą nazwę projektu",
    },
    format: {
      pattern: /^[a-zA-Z-]*$/,
      message: "Tylko małe litery i myślniki (bez polskich znaków).",
    },
    unique_slug: true,
  },
  description: {

  },
};

export default function ProjectEditorForm({
  project, slugs,
  close, save,
  canClose = false, isEditing=false,
}) {
  const [data, setData] = useState({});
  const [validationStatus, setValidationStatus] = useState({});

  useEffect(() => {
    if (project !== undefined)
      setData({...project});
    else
      setData({});
  }, [project]);

  const setData$ = (field) => (value) => {
    setData({
      ...data,
      [field]: value,
    })
  };

  const innerSave = () => {
    const newValidationStatus = validate(
      data,
      validations,
      {
        existingSlugs: slugs,
        currentSlug: isEditing ? project.slug : undefined,
        fullMessages: false,
      });


    if (!newValidationStatus) {
      setValidationStatus({});
      save(data);
      if (canClose) {
        close();
      }
    } else {
      setValidationStatus(newValidationStatus);
    }
  };

  return <>
    <FormGroup
      label="Nazwa"
      labelInfo="(wymagana)"
      labelFor="name"

      helperText={validationStatus["name"]}
      intent={"name" in validationStatus ? Intent.DANGER : Intent.NONE}
    >
      <InputGroup id="name"
        value={data["name"] || ''}
        onChange={e => setData$("name")(e.target.value)}
        intent={"name" in validationStatus ? Intent.DANGER : Intent.NONE}
      />
    </FormGroup>
    <FormGroup
      label="Uproszczona nazwa"
      labelInfo="(wymagana)"
      labelFor="slug"

      helperText={validationStatus["slug"]
          ? validationStatus["slug"]
          : "tylko małe litery i myślniki, bez polskich znaków"}
      intent={"slug" in validationStatus ? Intent.DANGER : Intent.NONE}
        >
        <InputGroup id="slug"
      value={data["slug"] || ''}
      onChange={e => setData$("slug")(e.target.value)}
      intent={"slug" in validationStatus ? Intent.DANGER : Intent.NONE}

      placeholder="używana w adresie projektu"
        />
        </FormGroup>
      <FormGroup
        label="Opis"
        labelFor="description"

        helperText={validationStatus["description"]}
        intent={"description" in validationStatus ? Intent.DANGER : Intent.NONE}
      >
        <TextArea id="description"
          value={data["description"] || ''}
          onChange={e => setData$("description")(e.target.value)}
          intent={"description" in validationStatus ? Intent.DANGER : Intent.NONE}

          rows={4}

          fill={true}
        />
      </FormGroup>
    <ButtonGroup
      fill={true}
    >
      {canClose &&
        <Button
          icon={IconNames.CROSS}
          text="Anuluj"

          onClick={close}
        />
      }
      <Button
        icon={IconNames.CLOUD_UPLOAD}
        intent={Intent.PRIMARY}
        text={isEditing ? "Zmień" : "Utwórz"}

        onClick={innerSave}
      />
    </ButtonGroup>
  </>
}