import Instance from "../actions/instance";
import {sortById} from "../utilities/redux";

const initialState = {
  initialised: false,
  projectsList: [],
  projectsById: {},
};

export default function instance(state = initialState, action) {
  switch(action.type) {
    case(Instance.TYPES.PROJECTS_LIST_CHANGED):
      const {projectsList} = action;

      return {
        ...state,

        initialised: true,
        projectsList,
        projectsById: sortById(projectsList),
      };

    case(Instance.TYPES.PROJECTS_LIST_ORDER_CHANGED):
      const {newOrderIds} = action;

      const reorder = (listById, ids) => {
        let newList = [];

        for (const id of ids) {
          newList.push(listById[id]);
        }

        return newList;
      };

      return {
        ...state,

        projectsList: reorder({...state.projectsById}, newOrderIds),
      };

    default:
      return state;
  }
}
