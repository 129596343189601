import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, ButtonGroup, Intent, Overlay} from "@blueprintjs/core";

import ContentWithSidebar, {Content, ContentToolbar, Sidebar} from "../layout/ContentWithSidebar";
import Project from "../../actions/project";

import "./InstanceProjects.scss"
import ProjectCard from "./subcomponents/ProjectCard";
import MyClasses from "../../shared/MyClasses";
import {IconNames} from "@blueprintjs/icons";
import CreateProjectOverlay from "./subcomponents/CreateProjectOverlay";
import ProjectDetails from "./subcomponents/ProjectDetails";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import reorder from "../../utilities/reorder";
import {getPlayServerAddress} from "../../utilities/url";

export default class InstanceProjects extends Component {

  state = {
    overlays: {
      createProject: false,
    }
  };



  componentDidMount() {
    this.loadProjectsList();
  }

  render() {
    const {initialised, projectsList, currentProject} = this.props;
    const {overlays} = this.state;

    if (!initialised) {
      return "";
    }

    return (
      <ContentWithSidebar className="InstanceProjects">
        <ContentToolbar>

        </ContentToolbar>
        <Content>
          <DragDropContext onDragEnd={this.orderChanged}>
            <Droppable droppableId="droppable-sessions" direction="vertical" type="sessions">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {projectsList.map((project, index) => (
                    <Draggable index={index} key={project["id"]} draggableId={`session-${project.id}`} >
                      {(provided, snapshot) => (
                        <div key={project.id}
                          className="project-card"
                          ref={provided.innerRef} {...provided.draggableProps}
                        >
                          <ProjectCard
                            dragHandleProps={provided.dragHandleProps}
                            isSelected={currentProject !== undefined && project.id === currentProject.id}
                            project={project}
                            selectProject={this.loadProject$(project.id)}
                            removeProject={this.removeProject$(project.id)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Content>
        <Sidebar>
          <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
            <Button large
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}

              onClick={this.toggleOverlay$("createProject")}
              text="Utwórz nowy projekt"
            />
          </ButtonGroup>
          <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
            <Button large
              icon={IconNames.PLAY}
              intent={Intent.NONE}

              onClick={() => window.open(getPlayServerAddress(currentProject["slug"]))}
              text="Odpal projekt"
              disabled={currentProject === undefined}
            />
          </ButtonGroup>
          <ProjectDetails
            project={currentProject}
          />
        </Sidebar>
        <InstanceProjects.Overlays
          visibility={overlays}
          toggle$={this.toggleOverlay$}
        />
      </ContentWithSidebar>
    );
  }

  static Overlays = ({visibility, toggle$}) => {
    return <>
      <Overlay
        isOpen={visibility["createProject"]}
        onClose={toggle$("createProject")}

        canEscapeKeyClose={true}
      >
        <CreateProjectOverlay
          toggleOverlay={toggle$("createProject")}
        />
      </Overlay>
    </>
  };

  toggleOverlay$ = (overlayName) => () => {
    this.setState(state => ({
      overlays: {
        ...state.overlays,
        [overlayName]: !state.overlays[overlayName],
      }
    }));
  };

  orderChanged = (result) => {
    // dropped outside the list
    if (this.state.disabled || !result.destination) {
      return;
    }

    let {projectsList} = this.props;

    projectsList = reorder(
      projectsList,
      result.source.index,
      result.destination.index
    );

    const projectsOrder = projectsList.map(project => project["id"]);
    this.reorderProjects(projectsOrder);
  };

  loadProjectsList = () => {
    this.props.loadProjectsList();
  };

  loadProject = projectId => {
    this.props.loadProject(projectId);
  };

  loadProject$ = (projectId) => () => {
    this.loadProject(projectId);
  };

  removeProject = (projectId) => {
    this.props.removeProject(projectId);
  };

  removeProject$ = (projectId) => () => {
    this.removeProject(projectId);
  };

  reorderProjects = (newOrder) => {
    this.props.reorderProjects(newOrder);
  }
}

const mapStateToProps = state => {
  const {instance, project} = state;

  return {
    initialised: instance.initialised,
    projectsList: instance.projectsList,
    projectsById: instance.projectsById,

    currentProject: project.currentProject,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadProjectsList: () => {
      dispatch(Project.loadProjectsList());
    },
    loadProject: projectId => {
      dispatch(Project.loadProject(projectId))
    },
    removeProject: projectId => {
      dispatch(Project.removeProject(projectId))
    },
    reorderProjects: newOrder => {
      dispatch(Project.reorderProjectsList(newOrder));
    }
  };
};

InstanceProjects = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InstanceProjects));