import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import config from 'react-global-configuration';
import { IconNames } from "@blueprintjs/icons";
import { PropTypes } from "prop-types";

import WithModalsComponentMixin from "../../mixins/WithModalsComponentMixin";
import CreateSessionModal from "./subcomponents/CreateSessionModal";
import ConfirmationModal from "../shared/ConfirmationModal";
import reorder from "../../utilities/reorder";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Intent,
} from "@blueprintjs/core";
import ContentWithSidebar, {Content, ContentToolbar, Sidebar} from "../layout/ContentWithSidebar";
import MyClasses from "../../shared/MyClasses";

import './SessionsList.scss';
import SessionsListTable from "./subcomponents/SessionsListTable";
import {connect} from "react-redux";
import Session from "../../actions/session";
import SessionDetails from "./subcomponents/SessionDetails";
import Project from "../../actions/project";
import ReactRouteComponent from "../../mixins/ReactRouteComponentMixin";
import {getPlayServerAddress} from "../../utilities/url";
import SprintClipboard from "./subcomponents/SprintClipboard";
import ButtonLink from "../ui/ButtonLink";
import {AdminPaths} from "../Admin/Admin";

class SessionsList extends ReactRouteComponent(WithModalsComponentMixin(Component)) {
  static propTypes = {
    currentSessionId: PropTypes.number,
    sessionsLoaded: PropTypes.bool,
    sessionList: PropTypes.array,
    sessionsByIndex: PropTypes.object,

    selectSessionById: PropTypes.func,
    updateCurrentSession: PropTypes.func,
    loadSessionList: PropTypes.func,
    reorderSessionList: PropTypes.func,
    createSession: PropTypes.func,
    removeSession: PropTypes.func,
  };


  constructor(props) {
    super(props);

    this.state = Object.assign(this.state, {
      disabled: false,
      modals: {
        createSession: false,
        removeSession: false,
      },
      removedSessionId: false,

      sessionList: [],
      sessionsByIndex: {},

      currentSessionUpdatedValues: {},

      redirectToSessionId: undefined,
    });
  }

  componentDidMount() {
    const projectId = this.getURLParameter('id');
    if (projectId) {
      this.projectSelected(Number.parseInt(projectId));
      this.loadProject(projectId);
    }

    this.loadSessionList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentSessionId !== this.props.currentSessionId) {
      this.currentSessionChanged();
    }
  }

  currentSessionChanged = () => {
    this.setState({
      currentSessionUpdatedValues: {},
    })
  };

  render() {
    let component;
    let redirectComponent = this.getRedirectComponent();
    const {currentSessionId, currentProjectId, currentProject, sessionList, copiedSprint} = this.props;
    const {disabled} = this.state;

    if (redirectComponent) {
      component = redirectComponent;
    } else if (!this.props.sessionsLoaded){
      component = "";
    } else {
      component = <SessionsListTable
        disabled={disabled} sessionList={sessionList} currentSessionId={currentSessionId}
        orderChanged={this.onDragEnd}
        deleteSessionById$={this.deleteSessionById$}
        selectSessionById$={this.selectSessionById$}
        copySprintById$={this.copySprintById$}
      />;
    }

    return <>
      <ContentWithSidebar className="SessionsList">
        <ContentToolbar>
          <ButtonLink minimal
            to={AdminPaths.projectList()}
            icon={IconNames.CIRCLE_ARROW_LEFT}
            text="Wróć do listy projektów"
          />
        </ContentToolbar>
        <Content>
          {component}
        </Content>
        <Sidebar>
          <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
            <Button
              large icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              onClick={this.toggleModalAction('createSession')}
            >
              Utwórz nowy sprint
            </Button>
          </ButtonGroup>
          <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
            <AnchorButton
              large icon="multi-select"
              href={`${config.get('serverAddress')}/admin/change-session/${currentProjectId}/`}
              target="_blank" rel="noopener noreferrer">
              Pilot
            </AnchorButton>
            <AnchorButton
              large icon="crown"
              href={`${config.get('serverAddress')}/admin/ranking/${currentProjectId}`}
              target="_blank" rel="noopener noreferrer">
              Ranking
            </AnchorButton>
          </ButtonGroup>
          <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
            <Button large
              icon={IconNames.PLAY}
              intent={Intent.NONE}

              onClick={() => window.open(getPlayServerAddress(currentProject["slug"]))}
              text="Odpal projekt"
              disabled={currentProject === undefined}
            />
          </ButtonGroup>
          <SessionDetails
            session={this.getCurrentSession()}
            setSessionName={this.setCurrentSessionParameter$('name')}
            updateSession={this.updateCurrentSession}
          />
          <SprintClipboard
            sprintId={copiedSprint["id"]}
            sprintName={copiedSprint["name"]}
            pasteSprint={this.pasteSprintToCurrentProject}
          />
        </Sidebar>
        {this.getModalComponents()}
      </ContentWithSidebar>
    </>
  }

  getCurrentSession = () => {
    const {currentSessionId, sessionsByIndex} = this.props;

    if (currentSessionId === undefined) {
      return undefined
    } else {
      return sessionsByIndex[currentSessionId];
    }
  };

  setCurrentSessionParameter = (parameter, value) => {
    this.setState(state => {
      return {
        currentSessionUpdatedValues: {
          ...state.currentSessionUpdatedValues,
          [parameter]: value,
        },
      }
    })
  };

  setCurrentSessionParameter$ = parameter => value => {
    this.setCurrentSessionParameter(parameter, value);
  };

  getModalComponents = () => {
    return (
      <div className="session-list-modals">
        <CreateSessionModal isOpen={this.state.modals['createSession']} toggle={this.toggleModalAction('createSession')} onCreate={this.createSession}/>
        <ConfirmationModal header="Usuwanie sesji" description={`Czy na pewno chcesz usunąć tę sesję?`}
          actionName="Usuń" onConfirmed={this.confirmSessionRemoval}
          isOpen={this.state.modals['removeSession']} toggle={this.toggleModalAction('removeSession')}/>
      </div>
    );
  };

  getRedirectComponent = () => {
    if (this.state.redirectToSessionId !== undefined) {
      return (
        <Redirect push to={`/session/${this.state.redirectToSessionId}`} />
      );
    } else {
      return false;
    }
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (this.state.disabled || !result.destination) {
      return;
    }

    let sessionList = reorder(
        this.props.sessionList,
        result.source.index,
        result.destination.index
      );

    const sessionOrder = this.getSessionOrderList(sessionList);
    this.reorderSessionList(sessionOrder);
  };

  getSessionOrderList = (sessionList) => {
    return sessionList.map((session, index) => {
      return session['id'];
    })
  };

  redirectToSessionId = (id) => {
    this.setState({
      redirectToSessionId: id
    })
  };

  selectSessionById$ = (sessionId) => () => {
    this.selectSessionById(sessionId);
  };

  copySprintById$ = (sprintId) => () => {
    this.copySprint(sprintId);
  };

  deleteSessionById$ = (sessionId) => () => {
    this.setState((state) => {
      state.modals.removeSession = true;

      return {
        modals: state.modals,
        removedSessionId: sessionId,
      };
    });
  };

  confirmSessionRemoval = () => {
    this.removeSession(this.state.removedSessionId);
  };

  projectSelected = projectId => {
    this.props.projectSelected(projectId);
  };

  loadProject = projectId => {
    this.props.loadProject(projectId);
  };

  selectSessionById = (sessionId) => {
    this.props.selectSessionById(sessionId)
  };

  updateCurrentSession = () => {
    this.props.updateCurrentSession(this.state.currentSessionUpdatedValues);
  };

  loadSessionList = () => {
    this.props.loadSessionList();
  };

  reorderSessionList = (sessionOrder) => {
    this.props.reorderSessionList(sessionOrder);
  };

  createSession = (sessionName) => {
    this.props.createSession(sessionName);
  };

  removeSession = (sessionId) => {
    this.props.removeSession(sessionId);
  };

  copySprint = (sprintId) => {
    const {sessionsByIndex} = this.props;
    let sprintName = undefined;
    if (sprintId !== undefined) {
      sprintName = sessionsByIndex[sprintId]['name'];
    }

    this.props.sprintCopied(sprintId, sprintName)
  };

  pasteSprintToCurrentProject = () => {
    const {currentProjectId} = this.props;
    this.props.copySprintToProject(currentProjectId);
  };
}

const mapStateToProps = state => {
  const {session, project} = state;

  return {
    currentProjectId: project.currentProjectId,
    currentSessionId: session.currentSessionId,

    currentProject: project.currentProject,

    sessionsLoaded: project.initialised,
    sessionList: project.sprintsList,
    sessionsByIndex: project.sprintsById,

    copiedSprint: session.copiedSprint,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    projectSelected: projectId => {
      dispatch(Project.projectSelected(projectId))
    },
    loadProject: projectId => {
      dispatch(Project.loadProject(projectId))
    },
    selectSessionById: sessionId => {
      dispatch(Session.selectSessionById(sessionId));
    },
    updateCurrentSession: values => {
      dispatch(Session.updateCurrentSession(values));
    },
    loadSessionList: () => {
      dispatch(Project.loadSprintsList());
    },
    reorderSessionList: (sessionOrder) => {
      dispatch(Project.reorderSprintsList(sessionOrder));
    },
    createSession: (sessionName) => {
      dispatch(Project.createSprint(sessionName));
    },
    removeSession: (sessionId) => {
      dispatch(Project.removeSprint(sessionId));
    },
    sprintCopied: (sprintId, sprintName) => {
      dispatch(Session.sprintCopied(sprintId, sprintName));
    },
    copySprintToProject: (projectId) => {
      dispatch(Session.copySprintToProject(projectId));
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionsList));