import ModuleInfo from "./_ModuleInfo";
import FIELD_TYPES from "../fieldTypes";


export default class AnswerCategoriesModuleInfo extends ModuleInfo {
  static exerciseParameters = [
    {
      id: "modules.answer_categories.active",
      description: "Aktywować podział odpowiedzi na kategorie?",
      type: FIELD_TYPES.bool,
      default: false,
    },
    {
      id: "modules.answer_categories.categories",
      description: "Nazwy kategorii",
      type: FIELD_TYPES.tags,
      visible: {
        type: 'value',
        section: 'exercise',
        value: "modules.answer_categories.active",
      }
    },
    {
      id: "modules.answer_categories.showSummary",
      description: "Pokaż podsumowanie kategorii wyborów odpowiedzi",
      type: FIELD_TYPES.bool,
      small: true,
      default: false,
      visible: {
        type: 'value',
        section: 'exercise',
        value: "modules.answer_categories.active",
      }
    },
    {
      id: "modules.answer_categories.showFeedback",
      description: "Pokaż feedback dotyczący dominującej kategorii",
      type: FIELD_TYPES.bool,
      small: true,
      default: false,
      visible: {
        type: 'value',
        section: 'exercise',
        value: "modules.answer_categories.active",
      }
    },
    {
      id: "modules.answer_categories.feedback",
      description: "Feedback do poszczególnych kategorii",
      type: FIELD_TYPES.list,
      length: {
        type: "same_as_other_list",
        section: "exercise",
        value: "modules.answer_categories.categories",
      },
      default: [],
      visible: {
        type: 'value',
        section: 'exercise',
        value: "modules.answer_categories.showFeedback",
      }
    },
  ];

  static answerParameters = [
    {
      id: "modules.answer_categories.category",
      description: "Kategoria",
      type: FIELD_TYPES.radio,
      default: 0,
      valuesFrom: {
        section: "exercise",
        value: "modules.answer_categories.categories"
      },
      visible: {
        type: 'value',
        section: 'exercise',
        value: "modules.answer_categories.active",
      }
    },
  ];
}