import React from "react";

import FIELD_TYPES from "exercises/fieldTypes";
import {defaultTo} from "lodash";

import NumericInput from "./NumericInput";
import BooleanInput from "./BooleanInput";
import TextInput from "./TextInput";
import RadioInput from "./RadioInput";

import "./Input.scss";
import TagInput from "./TagInput";
import ListInput from "./ListInput";

export default function Input({info, value, setValue, otherValues}) {
  let innerSetValue = setValue;
  let innerValue;

  if (info.subId !== undefined) {
    innerValue = (value === undefined ? info.default : value[info.subId]);

    innerSetValue = (newValue) => {
      setValue({
        ...value,
        [info.subId]: newValue,
      });
    }
  } else {
    innerValue = (value === undefined ? info.default : value);
  }

  if (info.visible !== undefined) {
    const {visible} = info;
    if (visible.type === 'value' && !otherValues[visible.section][visible.value]) {
      return null;
    }
  }

  let length = info.length;
  if (length !== undefined && typeof info.length !== "number") {
    if (length.type === "same_as_other_list") {
      length = defaultTo(otherValues[length.section][length.value], []).length;
    }
  }

  let values = info.values;
  if (info.valuesFrom !== undefined) {
    values = {...otherValues[info.valuesFrom.section][info.valuesFrom.value]};
  }


  switch(info.type) {
    case (FIELD_TYPES.number):
      return <NumericInput
        id={info.id}
        description={info.description}
        helperText={info.helperText}
        placeholder={info.placeholder}

        value={innerValue}
        setValue={innerSetValue}

        inline={info.inline}
        small={info.small}
      />;
    case (FIELD_TYPES.bool):
      return <BooleanInput
        id={info.id}
        description={info.description}
        helperText={info.helperText}
        labelChecked={info.labelChecked}
        labelUnchecked={info.labelUnchecked}

        useTextValues={info.useTextValues}

        value={innerValue}
        setValue={innerSetValue}

        inline={info.inline}
        small={info.small}
      />;
    case (FIELD_TYPES.radio):
      return <RadioInput
        id={info.id}
        description={info.description}
        helperText={info.helperText}

        value={innerValue}
        values={values}
        setValue={innerSetValue}

        inline={info.inline}
        small={info.small}
      />;
    case (FIELD_TYPES.string):
    case (FIELD_TYPES.text):
      const multiline = info.type === FIELD_TYPES.text;
      return <TextInput
        id={info.id}
        description={info.description}
        helperText={info.helperText}
        placeholder={info.placeholder}

        value={innerValue}
        setValue={innerSetValue}
        multiline={multiline}

        inline={info.inline}
        small={info.small}
      />;
    case (FIELD_TYPES.tags):
      return <TagInput
        id={info.id}
        description={info.description}
        helperText={info.helperText}
        placeholder={info.placeholder}

        value={innerValue}
        setValue={innerSetValue}

        inline={info.inline}
        small={info.small}
      />;
    case (FIELD_TYPES.list):
      return <ListInput
        id={info.id}
        description={info.description}
        helperText={info.helperText}
        placeholder={info.placeholder}

        value={innerValue}
        setValue={innerSetValue}

        length={length}

        inline={info.inline}
        small={info.small}
      />;
    default:
      throw Error(`Unknown input type requested: ${info.type}`)
  }
}