import Global from './global';
import Session from "./session";

export default class Exercise {
  static TYPES = {
    EXERCISE_SELECTED: 'EXERCISE_SELECTED',
    EXERCISE_CHANGED: 'EXERCISE_CHANGED',
    EXERCISE_MODIFIED: 'EXERCISE_MODIFIED',

    EXERCISE_COPIED: 'EXERCISE_COPIED',
  };

  static selectExerciseById(id) {
    return {
      type: Exercise.TYPES.EXERCISE_SELECTED,
      id,
    }
  }

  static exerciseChanged({data, questions}) {
    return {
      type: Exercise.TYPES.EXERCISE_CHANGED,
      data,
      questions,
    }
  }

  static exerciseCopied(exerciseId, exerciseName) {
    return {
      type: Exercise.TYPES.EXERCISE_COPIED,
      exerciseId,
      exerciseName,
    }
  }

  static loadExercise(id) {
    return exerciseRequest(
      "Wczytuję ćwiczenie",
      "Nie mogę wczytać ćwiczenia",
      "Wystąpił błąd podczas wyczytywania ćwiczenia",
      {
        method: "GET",
        url: ``
      },
      {id},
    )
  }

  static saveCurrentExercise() {
    return exerciseRequest(
      "Zapisuję ćwiczenie",
      "Nie mogę zapisać ćwiczenia",
      "Wystąpił błąd podczas zapisywania ćwiczenia",
      {
        method: "POST",
        url: ``
      },
      {sendExerciseData: true, sendQuestions: true}
    )
  }

  static copyExerciseToSprint(sprintId) {
    return exerciseRequest(
      "Kopiuję ćwiczenie",
      "Nie mogę skopiować ćwiczenia",
      "Wystąpił błąd podczas kopiowania ćwiczenia",
      {
        method: 'POST',
        url: `copy-to-sprint/${sprintId}`,
      },
      {
        useCopiedExerciseId: true,
        shouldUpdateSession: true,
      }
    )
  }
}

function exerciseRequest(processingMessage, errorMessage, requestErrorMessage, requestOptions, options={
  id: undefined,
  useCopiedExerciseId: false,
  shouldUpdateSession: false,
  sendExerciseData: false,
  sendQuestions: false,
}) {
  let {id, sendExerciseData, sendQuestions, useCopiedExerciseId, shouldUpdateSession} = options;

  return async (dispatch, getState) => {
    const {exercise} = getState();

    if (useCopiedExerciseId) {
      id = exercise.copiedExercise['id'];
    } else if (id === undefined) {
      id = exercise.id;
    }

    const closingSlash = requestOptions['url'] ? "/" : "";

    requestOptions['url'] = `admin/exercise/${id}` + closingSlash + requestOptions['url'];
    if (sendExerciseData || sendQuestions) {
      const exerciseState = exercise;
      if (!('data' in requestOptions)) {
        requestOptions['data'] = {}
      }

      if (sendExerciseData) {
        requestOptions['data']['exercise'] = exerciseState.data;
      }

      if (sendQuestions) {
        requestOptions['data']['questions'] = exerciseState.questions;
      }

    }

    try {
      dispatch(Global.startProcessing(processingMessage));
      const {data, status} = await window.axios(requestOptions);
      if (status !== 200) {
        dispatch(Global.errorOccurred(errorMessage));
      } else {
        if (shouldUpdateSession)
          dispatch(Session.loadExercisesList());
        const {exercise, questions} = data;
        dispatch(Exercise.exerciseChanged({data:exercise, questions}));
        dispatch(Global.finishProcessing());
      }
    } catch(error) {
      dispatch(Global.errorOccurred(requestErrorMessage));
    }
  }
}