import PropTypes from 'prop-types'
import React, {Component} from 'react';
import {Card, H1, Overlay, Intent, Button} from "@blueprintjs/core";

export default class ConfirmationModal extends Component {
  static propTypes = {
    header: PropTypes.string,
    description: PropTypes.string.isRequired,
    actionName: PropTypes.string.isRequired,

    onConfirmed: PropTypes.func.isRequired,

    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  //#region React
  render() {
    return (
      <Overlay className="ConfirmationModal" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <div>
          <Card>
            <H1>{ this.props.header ? this.props.header : this.props.actionName }</H1>
            <p>{ this.props.description }</p>
            <Button onClick={this.props.toggle}>Anuluj</Button>
            <Button onClick={this.confirm} intent={Intent.PRIMARY}>{ this.props.actionName }</Button>
          </Card>
        </div>
      </Overlay>
    )
  }
  //#endregion React

  //#region Actions
  confirm = () => {
    this.props.toggle();
    this.props.onConfirmed();
  };
  //#endregion Actions
}