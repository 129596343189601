import React from "react";
import {AnchorButton, ButtonGroup, H1, HTMLTable, Icon, Intent, Tooltip} from "@blueprintjs/core";
import classNames from "classnames";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {IconNames} from "@blueprintjs/icons";

import ButtonLink from "../../ui/ButtonLink";

import "./SessionsListTable.scss";

export default function SessionsListTable({
  // variables
  disabled, sessionList, currentSessionId,
  // functions
  orderChanged, deleteSessionById$, selectSessionById$, copySprintById$,
}) {
  return (
    <div className="SessionsListTable">
      <H1>Lista sprintów</H1>
      <HTMLTable interactive striped condensed className={classNames({disabled})}>
        <thead>
          <tr>
            <th/>
            <th className="name">Nazwa</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={orderChanged}>
          <Droppable droppableId="droppable-sessions" direction="vertical" type="sessions">
            {(provided, snapshot) => (
              <tbody
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
              {sessionList.map((session, index) =>
                <Draggable index={index} key={index} draggableId={`session-${index}`} >
                  {(provided, snapshot) => (
                    <tr ref={provided.innerRef} {...provided.draggableProps} key={index}
                      className={classNames({
                        'dragged': snapshot['isDragging'],
                        'selected': session['id'] === currentSessionId,
                      })}
                    >
                      <td className="drag-handle" {...provided.dragHandleProps}><Icon icon={IconNames.DRAG_HANDLE_VERTICAL} /></td>
                      <td onClick={selectSessionById$(session['id'])}>{ session.name }</td>
                      <td className="actions">
                        <ButtonGroup large>
                          <Tooltip content="Edytuj sprint" hoverOpenDelay={500}>
                            <ButtonLink
                              to={`/session/${session['id']}`}
                              icon={IconNames.EDIT}
                              intent={Intent.PRIMARY}
                            />
                          </Tooltip>
                          <Tooltip content="Kopiuj sprint" hoverOpenDelay={500}>
                            <AnchorButton
                              onClick={copySprintById$(session['id'])}
                              icon={IconNames.DUPLICATE} intent={Intent.NONE} />
                          </Tooltip>
                          <Tooltip content="Edytuj sprint" hoverOpenDelay={500}>
                            <AnchorButton
                              onClick={deleteSessionById$(session['id'])}
                              icon={IconNames.TRASH} intent={Intent.DANGER} />
                          </Tooltip>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )}
                </Draggable>
              )
              }
              {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
        {/*<tbody>*/}

        {/*</tbody>*/}
      </HTMLTable>
    </div>
  );
};