import React from 'react';
import {Link} from "react-router-dom";
import classNames from 'classnames';
import {Classes} from "@blueprintjs/core";

export default function ButtonLink({to, className, icon, intent, large, text, minimal, disabled, ...other}) {
  return <Link
    className={classNames(
      className,
      Classes.BUTTON,
      {
        [Classes.LARGE]: large,
        [`bp3-icon-${icon}`]: icon,
        [`bp3-intent-${intent}`]: intent,
        'bp3-minimal': minimal,
        'bp3-disabled': disabled,
      })}
    to={to}
    onClick={(e) => {
      if (disabled) {
        e.preventDefault();
      }
    }}
    {...other}
  >
      {text}
  </Link>
}