import Exercise from "../actions/exercise";
import {sortById} from "../utilities/redux";

const initialState = {
  id: undefined,
  data: undefined,
  questions: [],
  questionsById: {},
  loaded: false,
  deletedIds: [],

  copiedExercise: {
    id: undefined,
    name: undefined,
  }
};

export default function exercise(state = initialState, action) {
  switch (action.type) {
    case Exercise.TYPES.EXERCISE_SELECTED: {
      const {id} = action;

      return {
        ...state,
        loaded: state.loaded && state.id === id,
        id,
      };
    }

    case Exercise.TYPES.EXERCISE_CHANGED: {
      const {data, questions} = action;
      let stateChanges = {};

      if (data !== undefined) {
        stateChanges["data"] = data;
      }

      if (questions !== undefined) {
        stateChanges["questions"] = questions;
        stateChanges["questionsById"] = sortById(questions);
      }

      return {
        ...state,
        loaded: true,
        ...stateChanges,
      };
    }

    case Exercise.TYPES.EXERCISE_COPIED:
      const {exerciseId, exerciseName} = action;
      return {
        ...state,
        copiedExercise: {
          ...state.copiedExercise,
          id: exerciseId,
          name: exerciseName,
        },
      };

    case Exercise.TYPES.EXERCISE_MODIFIED: {
      const {data} = action;

      return {
        ...state,
        data: {
          ...state.data,
          ...data
        }
      }
    }

    default:
      return state;
  }
};