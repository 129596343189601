import React from "react";
import {BrowserRouter, Link, Route} from "react-router-dom";
import {Alignment, Button, Navbar} from "@blueprintjs/core";
import logoBlue from "../../images/logo/logo-white.svg";
import SessionsList from "../SessionsList/SessionsList";
import Session from "../Session/Session";
import Exercise from "../Exercise/Exercise";
import InstanceProjects from "../InstanceProjects/InstanceProjects";
import PropTypes from 'prop-types';

import "./Admin.scss";
import {Classes} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import Auth from "../../actions/auth";
import {connect} from "react-redux";
import Report from "../Report/Report";


function Admin({logout}) {
  return (
    <BrowserRouter>
      <div className="Admin">
        <Navbar className={Classes.DARK} fixedToTop>
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>
              <Link to="/"><img src={logoBlue} alt="SprinTech Logo" /></Link>
              <Link className="title" to="/">SprinTech Learning</Link>
            </Navbar.Heading>
            <Navbar.Divider />
          </Navbar.Group>
          <Navbar.Group align={Alignment.LEFT}>
            <Link to="/session/">
              <Button icon="th-list" minimal>
                Sesje
              </Button>
            </Link>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <Button minimal
              icon={IconNames.LOG_OUT} text="Wyloguj"
              onClick={logout}
            />
          </Navbar.Group>
        </Navbar>
        <div id="main">
          <Route path="/" exact component={InstanceProjects} />
          <Route path="/project/:id" component={SessionsList} />
          <Route path="/session/:id" component={Session} />
          <Route path="/exercise/:id" component={Exercise} />
          <Route path="/report/:exerciseId/:sprintId/:projectId" component={Report} />
        </div>
      </div>
    </BrowserRouter>
  );
}

Admin.propTypes = {
  logout: PropTypes.func,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(Auth.logout());
    }
  };
};

export class AdminPaths {
  static projectList() {
    return `/`;
  }

  static openExercise(exerciseId) {
    return `/exercise/${exerciseId}`
  }

  static openSession(sessionId) {
    return `/session/${sessionId}`;
  }

  static openProject(projectId) {
    return `/project/${projectId}`; // FIXME: Update po dodaniu obsługi instancji
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Admin);