import FIELD_TYPES from "../../fieldTypes";

export const answerWithFeedback = [
  {
    id: "feedback",
    type: FIELD_TYPES.text,
    description: "Informacja zwrotna",
    placeholder: "Wpisz informację, która pojawia się po wyborze odpowiedzi",
    default: "",
  }
];

export const answerWithCorrectIncorrectFeedback = [
  {
    id: "correctFeedback",
    type: FIELD_TYPES.text,
    description: "Informacja zwrotna - prawidłowy wybór",
    placeholder: "Wpisz informację, która pojawia się po prawidłowym wytypowaniu odpowiedzi",
    default: "",
  },
  {
    id: "incorrectFeedback",
    type: FIELD_TYPES.text,
    description: "Informacja zwrotna - nieprawidłowy wybór",
    placeholder: "Wpisz informację, która pojawia się po nieprawidłowym wytypowaniu odpowiedzi",
    default: "",
  }
];

export const newAnswerWithFeedbackObject = () => ({
  content: "",
  correct: false,
  parameters: {
    feedback: "",
  }
});