import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './utilities/serviceWorker';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from 'redux-devtools-extension';

import App from './App';

import sprintechAdminApp from "./reducers/_app";
import './scss/common.scss';
import './config';

const store = createStore(
  sprintechAdminApp,
  composeWithDevTools(applyMiddleware(
    thunk,
  )),
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
