import Global from "./global";

export default class Auth {
  static TYPES = {
    FINISHED_AUTHORIZATION_CHECK: 'FINISHED_AUTHORIZATION_CHECK',
    LOGOUT: 'LOGOUT',
  };

  static finishedAuthorizationCheck(isAuthorized, roles = []) {
    return {
      type: Auth.TYPES.FINISHED_AUTHORIZATION_CHECK,
      isAuthorized,
      roles,
    }
  }

  static checkAuthorization() {
    return dispatch => {
      dispatch(Global.startProcessing("Logowanie"));
      checkUsernameTokenLogin(
        () => {
          dispatch(Auth.finishedAuthorizationCheck(false));
          dispatch(Global.finishProcessing());
        },
        (roles) => {
          dispatch(Auth.finishedAuthorizationCheck(true, roles));
          dispatch(Global.finishProcessing());
        }
      );
    }
  }

  static checkAuthorizationForUserAndPassword(username, password) {
    return dispatch => {
      dispatch(Global.startProcessing("Logowanie"));
      checkUsernamePasswordLogin(
        username,
        password,
        () => {
          dispatch(Auth.finishedAuthorizationCheck(false));
          dispatch(Global.finishProcessing());
        },
        (token, roles) => {
          window.localStorage.setItem('username', username);
          window.localStorage.setItem('token', token);
          dispatch(Auth.finishedAuthorizationCheck(true, roles))
          dispatch(Global.finishProcessing())
        }
      );
    }
  }

  static logout() {
    return {
      type: Auth.TYPES.LOGOUT,
    }
  }
}

function checkUsernameTokenLogin(onFailure, onSuccess) {
  const username = window.localStorage.getItem('username');
  const token = window.localStorage.getItem('token');

  if (!username || !token) {
    onFailure();
  } else {
    window.axios({
      method: 'POST',
      url: `auth/token`,
      data: {
        username: username,
        token: token,
      }
    }).then(
      response => {
        const {status, roles} = response.data;
        if (status === 'Success') {
          onSuccess(roles);
        } else {
          onFailure();
        }
      },
      error => {
        onFailure();
      }
    )
  }
}

function checkUsernamePasswordLogin(username, password, onFailure, onSuccess) {
  window.axios({
    method: 'POST',
    url: `auth/login`,
    data: {
      username,
      password,
    }
  }).then(
    response => {
      const {status, token, roles} = response.data;
      if (status === 'Success') {
        onSuccess(token, roles);
      } else {
        onFailure();
      }
    },
    error => {
      onFailure();
    }
  )
}