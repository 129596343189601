import React from "react";
import QuestionParameters from "./QuestionParameters";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {Card, EditableText, Icon, Button, Intent, H3} from "@blueprintjs/core";
import classNames from 'classnames';

import "./ExerciseQuestion.scss";
import {IconNames} from "@blueprintjs/icons";
import ExerciseAnswer from "./ExerciseAnswer";
import InfoFactory from "../../../exercises/ExerciseInfoFactory";

export default function ExerciseQuestion({
  exerciseType, exerciseParameters,
  question, questionIndex,
  setQuestionField$, deleteQuestion, setQuestionParameter$,
  setAnswerWithIndexField$$, deleteAnswerWithIndex$, setAnswerWithIndexParameter$$,
  addAnswerForQuestion$,
  dragHandleProps
}) {
  const canAddAnswer = InfoFactory.canAddAnswer(exerciseType);
  const questionInfo = InfoFactory.getQuestionInfo(exerciseType);

  return (
    <Card className="ExerciseQuestion">
      <div className={classNames("question-header", {"has-no-content": !questionInfo.hasContent})}>
        <H3 className="card-header">
          <Icon className="card-header-drag-icon"
            icon={IconNames.DRAG_HANDLE_VERTICAL}
            iconSize={Icon.SIZE_LARGE}
            {...dragHandleProps}
          />
          {questionInfo.hasContent &&
          <EditableText className="card-header-content"
            placeholder="Treść pytania"
            multiline

            value={question['content']}
            onChange={setQuestionField$('content')}
          />
          }
          <Button className="card-header-button"
            large
            icon={<Icon icon={IconNames.DELETE} iconSize={Icon.SIZE_LARGE} />}
            intent={Intent.DANGER}
            onClick={deleteQuestion}
          />
        </H3>
      </div>
      <div className="question-content">
        <QuestionParameters exerciseType={exerciseType} question={question} setQuestionParameter$={setQuestionParameter$} />
        <Droppable droppableId={`droppable-answers-${questionIndex}`} direction="vertical" type={`answers-${questionIndex}`}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {question.answers.map((answer, answerIndex) =>
                <Draggable index={answerIndex} key={answerIndex} draggableId={`answer-${questionIndex}-${answerIndex}`} >
                  {(provided, snapshot) => (
                    <div id={`answer-content-${questionIndex}`} className="answer" ref={provided.innerRef} {...provided.draggableProps}>
                      <ExerciseAnswer
                        exerciseType={exerciseType}
                        exerciseParameters={exerciseParameters}
                        questionParameters={question['parameters']}
                        answer={answer}

                        canDeleteAnswer={canAddAnswer}

                        setAnswerField$={setAnswerWithIndexField$$(answerIndex)}
                        deleteAnswer={deleteAnswerWithIndex$(answerIndex)}
                        setAnswerParameter$={setAnswerWithIndexParameter$$(answerIndex)}

                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {canAddAnswer &&
          <Button className="add-answer"
            intent={Intent.PRIMARY}
            icon={IconNames.ADD}
            text="Dodaj odpowiedź"
            onClick={addAnswerForQuestion$(questionIndex)}
          />
        }
      </div>
    </Card>
  )
}