import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Redirect, withRouter} from "react-router-dom";

import SessionActions from "../../actions/session";

import ReactRouteComponent from "../../mixins/ReactRouteComponentMixin";
import AddExerciseModal from "./subcomponents/AddExerciseModal";
import ExerciseTypes from "../../shared/ExerciseTypes";

import './Session.scss';
import ConfirmationModal from "../shared/ConfirmationModal";
import WithModalsComponentMixin from "../../mixins/WithModalsComponentMixin";
import reorder from "../../utilities/reorder";
import SessionTable from "./subcomponents/SessionTable";
import ContentWithSidebar, {Content, ContentToolbar, Sidebar} from "../layout/ContentWithSidebar";
import {ButtonGroup, Button, Intent} from "@blueprintjs/core";
import MyClasses from "../../shared/MyClasses";
import {IconNames} from "@blueprintjs/icons";
import {connect} from "react-redux";
import ButtonLink from "../ui/ButtonLink";
import {AdminPaths} from "../Admin/Admin";
import ExerciseClipboard from "./subcomponents/ExerciseClipboard";
import Exercise from "../../actions/exercise";

/**
 * @mixes ReactRouteComponent
 */
class Session extends WithModalsComponentMixin(ReactRouteComponent(Component)) {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }),

    selectSessionById: PropTypes.func,
    loadExerciseList: PropTypes.func,
    reorderExercisesList: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = Object.assign(this.state, {
      dataInitialised: false,
      disabled: false,
      error: false,
      
      session: {},
      exerciseList: [],
      
      modals: {
        addExercise: false,
        removeExercise: false,
      },

      removedExerciseId: undefined,
      
      redirectToExerciseId: undefined,
    });
  }
  
  componentDidMount() {
    const sessionId = this.getURLParameter('id');
    if (sessionId) {
      this.selectSessionById(Number.parseInt(sessionId));
    }

    this.loadExercisesList();
  }
  
  render() {
    if (this.state.redirectToExerciseId !== undefined)
      return <Redirect push to={`/exercise/${this.state.redirectToExerciseId}`}/>;

    const {currentProjectId, exercisesList, copiedExercise} = this.props;

    return <>
      <ContentWithSidebar className="SessionsList">
        <ContentToolbar>
          <ButtonLink minimal
            to={AdminPaths.openProject(currentProjectId)}
            icon={IconNames.CIRCLE_ARROW_LEFT}
            text="Wróć do projektu"
            disabled={currentProjectId === undefined}
          />
        </ContentToolbar>
        <Content>
          <SessionTable
            session={this.state.session}
            orderChanged={this.onDragEnd}
            disabled={this.state.disabled}
            currentExerciseId={this.state.currentExerciseId}
            deleteExerciseById$={this.removeExercise$}
            exerciseList={exercisesList}
            selectExerciseById$={() => {}}
            copyExerciseById$={this.copyExercise$}
          />
        </Content>
        <Sidebar>
          <ButtonGroup className={MyClasses.ButtonGroup.FullWidth}>
            <Button
              large icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              onClick={this.toggleModalAction('addExercise')}
              text="Dodaj ćwiczenie"
            />
          </ButtonGroup>
          <ExerciseClipboard
            exerciseId={copiedExercise["id"]}
            exerciseName={copiedExercise["name"]}
            pasteExercise={this.pasteExerciseToCurrentSprint}
          />
        </Sidebar>
        <AddExerciseModal
          toggle={this.toggleModalAction('addExercise')}
          isOpen={this.state.modals['addExercise']}
          addExercise={this.addExercise}
          exerciseTypes={ExerciseTypes}
        />
        <ConfirmationModal header="Usuwanie ćwiczenia z sesji" description={`Czy na pewno chcesz usunąć to ćwiczenie z sesji?`}
          actionName="Usuń" onConfirmed={this.removeExercise}
          isOpen={this.state.modals['removeExercise']} toggle={this.toggleModalAction('removeExercise')}/>
      </ContentWithSidebar>
    </>;
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (this.state.disabled || !result.destination) {
      return;
    }

    let exercisesList = reorder(
      this.props.exercisesList,
      result.source.index,
      result.destination.index
    );

    this.reorderExercisesList(this.getExercisesOrderList(exercisesList));
  };

  getExercisesOrderList = (exercisesList) => {
    return exercisesList.map((exercise) => {
      return exercise['id'];
    })
  };

  redirectToExerciseId = (exerciseId) => {
    this.setState({
      redirectToExerciseId: exerciseId,
    })
  };

  confirmExerciseRemovalAction = (exerciseId) => () => {
    this.setState((state) => {
      state.modals.removeExercise = true;

      return {
        modals: state.modals,
        removedExerciseId: exerciseId,
      };
    });
  };

  selectSessionById = (sessionId) => {
    this.props.selectSessionById(sessionId);
  };

  loadExercisesList = () => {
    this.props.loadExercisesList();
  };

  reorderExercisesList = (exercisesOrder) => {
    this.props.reorderExercisesList(exercisesOrder)
  };

  addExercise = (exercise) => {
    this.props.addExercise(exercise);
  };

  removeExercise = (exerciseId) => {
    this.props.removeExercise(exerciseId);
  };

  removeExercise$ = (exerciseId) => () => {
    this.removeExercise(exerciseId);
  };

  copyExercise = (exerciseId) => {
    const {exercisesById} = this.props;
    let exerciseName = undefined;
    if (exerciseId !== undefined) {
      exerciseName = exercisesById[exerciseId]['name'];
    }

    this.props.exerciseCopied(exerciseId, exerciseName)
  };

  copyExercise$ = (exerciseId) => () => {
    this.copyExercise(exerciseId);
  };

  pasteExerciseToCurrentSprint = () => {
    const {currentSessionId} = this.props;
    this.props.copyExerciseToSprint(currentSessionId);
  };
}

const mapStateToProps = state => {
  const {exercise, session, project} = state;

  return {
    currentProjectId: project.currentProjectId,
    currentSessionId: session.currentSessionId,

    exercisesLoaded: session.exercisesLoaded,
    exercisesList: session.exercisesList,
    exercisesById: session.exercisesById,

    copiedExercise: exercise.copiedExercise,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectSessionById: (sessionId) => {
      dispatch(SessionActions.selectSessionById(sessionId));
    },
    loadExercisesList: () => {
      dispatch(SessionActions.loadExercisesList());
    },
    reorderExercisesList: (exercisesOrder) => {
      dispatch(SessionActions.reorderExercisesList(exercisesOrder));
    },
    addExercise: (exercise) => {
      dispatch(SessionActions.addExercise(exercise));
    },
    removeExercise: (exerciseId) => {
      dispatch(SessionActions.removeExercise(exerciseId));
    },
    exerciseCopied: (exerciseId, exerciseName) => {
      dispatch(Exercise.exerciseCopied(exerciseId, exerciseName));
    },
    copyExerciseToSprint: (sprintId) => {
      dispatch(Exercise.copyExerciseToSprint(sprintId));
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Session));