import React from 'react';
import {Card, Elevation, NonIdealState, Overlay, Spinner} from "@blueprintjs/core";

import "./Overlay.scss";
import "./ProcessingOverlay.scss";

export default function ProcessingOverlay({isOpen, message}) {
  return <Overlay
    isOpen={isOpen}
    canEscapeKeyClose={false}
    canOutsideClickClose={false}

    className="ProcessingOverlay"
  >
    <div className="Overlay">
      <Card elevation={Elevation.THREE}>
        <NonIdealState
          icon={<Spinner />}
          title={message}
        />
      </Card>
    </div>
  </Overlay>
}