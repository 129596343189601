import React from "react";

import "./ListInput.scss";
import {FormGroup, InputGroup, Tag} from "@blueprintjs/core";
import classNames from "classnames";
import {defaultTo} from "lodash";

export default function ListInput({
  id, description, helperText,
  value, setValue, length,
  inline=false, small=false
}) {
  value = [...defaultTo(value, [...Array(length)].map(() => ""))];
  if (value.length > length) {
    value = value.slice(0, length);
  } else if (value.length < length) {
    value = value.concat(value, [...Array(length - value.length)].map(() => ""));
  }

  const setValueForIndex$ = (index) => (newValue) => {
    value[index] = newValue.target.value;
    setValue(value);
  };

  return <FormGroup
    className={classNames("ListInput", "Input", {"small": small})}
    id={id}
    inline={inline}
    label={description}
    helperText={helperText}
  >
    { [...Array(length).keys()].map((index) => (
      <InputGroup key={index}
        fill={true}
        type="text"
        rightElement={<Tag>{index}</Tag>}

        value={value[index]}
        onChange={setValueForIndex$(index)}
      />
    ))}
  </FormGroup>
}