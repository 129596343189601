import PropTypes from 'prop-types'
import React, {Component} from 'react';
import {Card, H1, Overlay, Button, ButtonGroup, Intent} from "@blueprintjs/core";
import classNames from 'classnames';
import {Select} from "@blueprintjs/select";

import './AddExerciseModal.scss';

export default class AddExerciseModal extends Component {
  static propTypes = {
    addExercise: PropTypes.func.isRequired,
    exerciseTypes: PropTypes.arrayOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: undefined,

      adding: false,
    }
  }

  render() {
    const {selected} = this.state;
    const {exerciseTypes} = this.props;

    return (
      <Overlay isOpen={this.props.isOpen} toggle={this.props.toggle} enforceFocus={false}>
        <div>
          <Card className="AddExerciseModal">
            <H1>Dodawanie ćwiczenia</H1>
            <Select className="exercise-select" items={exerciseTypes} itemRenderer={this.exerciseRenderer} onItemSelect={this.exerciseSelected}>
              <Button text={selected ? <strong>{selected.name}</strong> : "Wybierz ćwiczenie"} rightIcon="double-caret-vertical" />
            </Select>
            <ButtonGroup className={classNames("modal-buttons")}>
              <Button intent={Intent.NONE} onClick={this.props.toggle}>
                Anuluj
              </Button>
              <Button intent={Intent.PRIMARY}onClick={this.addExercise} disabled={!this.state.selected}>
                Dodaj ćwiczenie
              </Button>
            </ButtonGroup>
          </Card>
        </div>
      </Overlay>
    )
  }

  exerciseRenderer = (exercise, {handleClick, modifiers}) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else {
      return (
        <div
          className={classNames("select-exercise-choice")}
          onClick={handleClick}
          key={exercise.id}
        >
          <h4>{exercise.name}</h4>
          <p>{exercise.description}</p>
        </div>
      );
    }
  };

  //#region Actions
  exerciseSelected = (exercise) => {
    console.log(exercise.name);
    this.setState({
      selected: exercise,
    })
  };

  addExercise = () => {
    let selectedExercise = this.state.selected;

    if (selectedExercise) {
      this.props.addExercise(selectedExercise);
    }

    this.props.toggle();
  };
  //#endregion Actions
}