import {combineReducers} from "redux";

import global from './global';
import auth from "./auth";
import instance from "./instance";
import project from "./project";
import session from "./session";
import exercise from "./exercise";
import stats from "./stats";


const sprintechAdminApp = combineReducers(
  {
      global,
      auth,

      instance,
      project,
      session,
      exercise,
      stats,
  },
);

export default sprintechAdminApp;