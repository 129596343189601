import DefaultInfo from "./_default";
import {answerWithFeedback, newAnswerWithFeedbackObject} from "./common/answer";
import {exerciseWithTime} from "./common/exercise";

export default class TrainInfo extends DefaultInfo {
  static exercise = {
    ...DefaultInfo.exercise,
    parameters: [
      ...exerciseWithTime,
    ]
  };

  static answer = {
    ...DefaultInfo.answer,
    parameters: [
      ...answerWithFeedback
    ]
  };

  static newAnswer = () => (
    {
      ...newAnswerWithFeedbackObject()
    }
  );
};