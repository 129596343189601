import Global from "../actions/global";

const initialState = {
  error: {
    show: false,
    message: "",
  },
  processing: {
    show: false,
    message: "",
    queue: [],
  },
};

export default function global(state=initialState, action={}) {
  switch(action.type) {
    case Global.TYPES.START_PROCESSING: {
      let queue = [
        ...state.processing.queue,
        action.message,
      ];

      let message = state.processing.message;
      if (!state.processing.show) {
        message = action.message;
      }

      return {
        ...state,
        processing: {
          ...state.processing,
          show: true,
          message,
          queue,
        }
      };
    }
    case Global.TYPES.FINISH_PROCESSING: {
      let queue = [
        ...state.processing.queue,
      ];
      queue.shift();

      let [message, show] = ["", false];
      if (queue.length > 0) {
        message = queue[0];
        show = true;
      }

      return {
        ...state,
        processing: {
          ...state.processing,
          message,
          show,
          queue,
        }
      };
    }
    case Global.TYPES.ERROR_OCCURRED:
      return {
        ...state,
        error: {
          ...state.error,
          show: true,
          message: action.message,
        },
      };

    default:
      return state;
  }
}