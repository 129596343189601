import DefaultInfo from "./_default";


class OpenQuestionInfo extends DefaultInfo {
  static answer = {
    hasNoCorrectIncorrect: true,
    parameters: [],
  };

  static canAddAnswer = () => false;

  static newQuestion = () => (
    {
      content: "",
      parameters: {},
      answers: [
        this.newAnswer(),
        this.newAnswer(),
        this.newAnswer(),
        this.newAnswer(),
      ]
    }
  );
}

export default OpenQuestionInfo;