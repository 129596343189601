import React from 'react';
import {FormGroup, NumericInput as BlueprintNumericInput} from "@blueprintjs/core";
import classNames from 'classnames';

import "./NumericInput.scss";

export default function NumericInput({
  id, description, helperText, placeholder,
  value, setValue,
  inline=false, small=false}) {
  return (
    <FormGroup
      className={classNames("NumericInput", "Input", {"small": small})}
      inline={inline}
      label={description}
      labelFor={id}
      helperText={helperText}
    >
      <BlueprintNumericInput id={id}
        placeholder={placeholder}

        value={value}
        onValueChange={setValue}
      />
    </FormGroup>
  );
}