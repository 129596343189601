import Session from "../actions/session";
import {sortById} from "../utilities/redux";

const initialState = {
  currentSessionId: undefined,
  currentSession: undefined,

  exercisesLoaded: false,
  exercisesList: [],
  exercisesById: {},

  copiedSprint: {
    id: undefined,
    name: undefined,
  },
};

export default function session(state = initialState, action) {
  switch (action.type) {
    case Session.TYPES.SESSION_SELECTED:
      return {
        ...state,
        currentSessionId: action.id,
      };

    case Session.TYPES.SPRINT_COPIED:
      const {sprintId, sprintName} = action;
      return {
        ...state,
        copiedSprint: {
          ...state.copiedSprint,
          id: sprintId,
          name: sprintName,
        },
      };

    case Session.TYPES.EXERCISE_LIST_CHANGED:
      const {exercisesList} = action;
      return {
        ...state,
        exercisesLoaded: true,
        exercisesList,
        exercisesById: sortById(exercisesList),
      };

    case Session.TYPES.SESSION_CHANGED:
      const {session} = action;
      return {
        ...state,
        session,
      };

    default:
      return state;
  }
};
