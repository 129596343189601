import React from "react";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  H1,
  HTMLTable,
  Icon,
  Intent,
  Popover,
  Classes,
  Tooltip
} from "@blueprintjs/core";
import classNames from "classnames";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {IconNames} from "@blueprintjs/icons";

import ButtonLink from "../../ui/ButtonLink";

import "./SessionTable.scss";

export default function SessionTable({
  // variables
  session, disabled, exerciseList, currentExerciseId,
  // functions
  orderChanged, deleteExerciseById$, selectExerciseById$, copyExerciseById$
}) {
  return (
    <div className="SessionTable">
      <H1>Sesja {session['name']}</H1>
      <HTMLTable interactive striped condensed className={classNames({disabled})}>
        <thead>
        <tr>
          <th/>
          <th className="name">Nazwa</th>
          <th>Akcje</th>
        </tr>
        </thead>
        <DragDropContext onDragEnd={orderChanged}>
          <Droppable droppableId="droppable-sessions" direction="vertical" type="sessions">
            {(provided, snapshot) => (
              <tbody
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
              {exerciseList.map((exercise, index) =>
                <Draggable index={index} key={index} draggableId={`exercise-${index}`} >
                  {(provided, snapshot) => (
                    <tr ref={provided.innerRef} {...provided.draggableProps} key={index}
                      className={classNames({
                        'dragged': snapshot['isDragging'],
                        'selected': exercise['id'] === currentExerciseId,
                      })}
                    >
                      <td className="drag-handle" {...provided.dragHandleProps}><Icon icon={IconNames.DRAG_HANDLE_VERTICAL} /></td>
                      <td onClick={selectExerciseById$(exercise['id'])}>{ exercise.name }</td>
                      <td className="actions">
                        <ButtonGroup large={true}>
                          <Tooltip content="Edytuj sprint" hoverOpenDelay={500}>
                            <ButtonLink
                              to={`/exercise/${exercise['id']}`}
                              icon={IconNames.EDIT} intent={Intent.PRIMARY} />
                          </Tooltip>
                          <Tooltip content="Kopiuj sprint" hoverOpenDelay={500}>
                            <AnchorButton
                              onClick={copyExerciseById$(exercise['id'])}
                              icon={IconNames.DUPLICATE} intent={Intent.NONE} />
                          </Tooltip>
                          <Popover content={
                            <>
                              <h4>Czy na pewno chcesz usunąć te ćwiczenie?</h4>
                              <ButtonGroup className={classNames("modal-buttons")}>
                                <Button intent={Intent.NONE} text="Anuluj" className={Classes.POPOVER_DISMISS} />
                                <Button intent={Intent.DANGER} text="Tak" onClick={deleteExerciseById$(exercise['id'])} />
                              </ButtonGroup>
                            </>
                          }>
                            <Tooltip content="Edytuj sprint" hoverOpenDelay={500}>
                              <AnchorButton
                                icon={IconNames.TRASH} intent={Intent.DANGER} />
                            </Tooltip>
                          </Popover>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )}
                </Draggable>
              )
              }
              {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </HTMLTable>
    </div>
  );
};