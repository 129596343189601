import PropTypes from 'prop-types'
import React, {Component} from 'react';
import WithFormComponentMixin from "../../../mixins/WithFormComponentMixin";
import {Card, H1, Overlay, FormGroup, InputGroup, Button, Intent} from "@blueprintjs/core";

export default class CreateSessionModal extends WithFormComponentMixin(Component) {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,

    onCreate: PropTypes.func.isRequired
  };

  //#region React
  constructor(props) {
    super(props);

    this.state = {
      sessionName: '',
    }
  }

  render() {
    return (
      <Overlay className="CreateSessionModal" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <div>
          <Card>
            <H1>Tworzenie sesji</H1>
            <form onSubmit={this.submitted}>
              <FormGroup
                label="Nazwa sesji"
                labelFor="sessionName"
              >
                <InputGroup type="text" name="sessionName" id="sessionName" placeholder="wpisz nazwę tworzonej sesji" onChange={this.handleInputChange}/>
              </FormGroup>
              <Button onClick={this.props.toggle}>Anuluj</Button>
              <Button onClick={this.createSession} disabled={!this.state.sessionName} intent={Intent.PRIMARY}>Utwórz sesję</Button>
            </form>
          </Card>
        </div>
      </Overlay>
    )
  }
  //#endregion React

  //#region Actions
  createSession = () => {
    let name = this.state.sessionName;

    this.setState({
      sessionName: '',
    }, () => {
      this.props.toggle();
      this.props.onCreate(name);
    });
  };

  submitted = (event) => {
    event.preventDefault();
    this.createSession();
  }
  //#endregion Actions
}