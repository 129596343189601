import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Auth from "./actions/auth";

import './scss/App.scss';
import {connect} from "react-redux";
import Admin from "./components/Admin/Admin";
import Login from "./components/Login/Login";
import ProcessingOverlay from "./components/ui/ProcessingOverlay";
import ErrorOverlay from "./components/ui/ErrorOverlay";

class App extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool,
    wasAuthorizationChecked: PropTypes.bool,

    processing: PropTypes.shape({
      isProcessing: PropTypes.bool,
      message: PropTypes.string,
    }),

    checkAuthorization: PropTypes.func,
  };

  componentDidMount() {
    this.checkAuthorization();
  }

  render() {
    const {loggedIn, wasAuthorizationChecked, processing, error} = this.props;
    let mainComponent = "";

    if (loggedIn) {
      mainComponent = <Admin />;
    } else if (wasAuthorizationChecked) {
      mainComponent = <Login />;
    }

    return <>
      <ErrorOverlay
        isOpen={error.show}
        message={error.message}
      />
      <ProcessingOverlay
        isOpen={processing.show && !error.show}
        message={processing.message}
      />
      {mainComponent}
    </>
  }

  checkAuthorization = () => {
    this.props.checkAuthorization();
  };
}

const mapStateToProps = state => {
  const {auth, global} = state;

  return {
    processing: global.processing,
    error: global.error,

    wasAuthorizationChecked: auth.wasAuthorizationChecked,
    loggedIn: auth.loggedIn,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkAuthorization: () => {
      dispatch(Auth.checkAuthorization())
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
