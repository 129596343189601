import FIELD_TYPES from "../../fieldTypes";

export const exerciseWithTime = [
  {
    id: "timePerQuestionSeconds",
    type: FIELD_TYPES.number,
    description: "Czas na odpowiedź",
    placeholder: "liczba sekund",
    default: 60,
    inline: true,
    small: false,
  }
];