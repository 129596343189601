import React from 'react';
import {Radio, RadioGroup} from "@blueprintjs/core";
import classNames from 'classnames';

import "./RadioInput.scss";

export default function RadioInput({
  id, description, helperText,
  values, value, setValue,
  inline=false, small=false}) {

  let fullDescription = description;
  if (helperText) {
    fullDescription += `(${helperText})`;
  }

  return (
    <RadioGroup
      className={classNames("RadioInput", "Input", {"small": small})}

      label={fullDescription}
      inline={inline}
      onChange={e => setValue(e.target.value)}
      selectedValue={value}
    >
      {Object.entries(values).map(([value, label], index) => (
        <Radio key={index}
          value={value}
          label={label}
        />
      ))}
    </RadioGroup>
  );
}