import React from 'react';
import InfoFactory from "../../../exercises/ExerciseInfoFactory";
import Input from "../../ui/inputs/Input";

import "./QuestionParameters.scss";

export default function QuestionParameters({exerciseType, question, setQuestionParameter$}) {
  const questionInfo = InfoFactory.getQuestionInfo(exerciseType);

  return <>
    {questionInfo.parameters.length > 0 &&
    <div className="QuestionParameters">
      {questionInfo.parameters.map((info, index) => (
        <Input key={index}
          info={info}

          value={question.parameters[info.id]}
          setValue={setQuestionParameter$(info.id)}
        />
      ))}
    </div>
    }
  </>;
}