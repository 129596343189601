const FIELD_TYPES = {
  number: "number",
  bool: "bool",
  radio: "radio",
  string: "string",
  text: "text",
  tags: "tags",
  list: "list",
};

export default FIELD_TYPES;