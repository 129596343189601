import DefaultInfo from "./_default";
import FIELD_TYPES from "../fieldTypes";
import {exerciseWithTime} from "./common/exercise";


class MemoryInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...exerciseWithTime,
      {
        id: "onlyImages",
        description: "Klasyczne memory (same obrazki)",
        type: FIELD_TYPES.bool,
        default: false,

        small: true,
      },
      {
        id: "showSummary",
        description: "Pokazać podsumowanie czasu?",
        type: FIELD_TYPES.bool,
        default: false,

        small: true,
      }
    ],
  };

  static answer = {
    hasNoCorrectIncorrect: true,
    parameters: [
      {
        id: "image",
        type: FIELD_TYPES.text,
        description: "Nazwa zdjęcia",
        placeholder: "Nazwa zdjęcia wyświetlanego dla tej odpowiedzi",
        default: "",

        small: false,
      },
    ],
  };

  static canAddAnswer = () => false;

  static newQuestion = () => (
    {
      content: "",
      parameters: {
        category1: {
          name: '',
          image: '',
        },
        category2: {
          name: '',
          image: '',
        }
      },
      answers: [
        this.newAnswer(1),
        this.newAnswer(1),
        this.newAnswer(2),
        this.newAnswer(2),
        this.newAnswer(3),
        this.newAnswer(3),
        this.newAnswer(4),
        this.newAnswer(4),
        this.newAnswer(5),
        this.newAnswer(5),
        this.newAnswer(6),
        this.newAnswer(6),
      ]
    }
  );

  static newAnswer = (id = 1) => (
    {
      content: "",
      correct: true,
      parameters: {
        id,
        image: '',
      }
    }
  );
}

export default MemoryInfo;