import React from "react";

import "./TagInput.scss";
import {FormGroup, TagInput as BlueprintTagInput} from "@blueprintjs/core";
import classNames from "classnames";
import {defaultTo} from "lodash";

export default function TagInput({
  id, description, helperText,
  value, setValue,
  inline=false, small=false
}) {

  return <FormGroup
    className={classNames("TagInput", "Input", {"small": small})}
    inline={inline}
    label={description}
    labelFor={id}
    helperText={helperText}
  >
    <BlueprintTagInput
      id={id}
      values={defaultTo(value, [])}
      onChange={setValue}
    />
  </FormGroup>
}