import React from "react";
import classNames from 'classnames';

import './ContentWithSidebar.scss';
import {Alignment, Button, ButtonGroup, Classes, Navbar} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

export default function ContentWithSidebar({children, className, ...other}) {
  return <div className={classNames('ContentWithSidebar', className)} {...other}>
    { children }
  </div>
}

export function ContentToolbar({children, className, includeGoToTop, ...other}) {
  const goUp = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return <Navbar className={classNames('ContentToolbar', 'bp3-dark', className)} {...other}>
    <Navbar.Group align={Alignment.LEFT}>
      { children }
    </Navbar.Group>
    <Navbar.Group align={Alignment.RIGHT}>
      <ButtonGroup>
        <Button minimal
          icon={IconNames.DOUBLE_CHEVRON_UP}
          text="Góra strony"
          onClick={goUp}
        />
      </ButtonGroup>
    </Navbar.Group>
  </Navbar>
}

export function Content({children, className, ...other}) {
  return <div className={classNames('Content', className)} {...other}>
    <div>
      { children }
    </div>
  </div>
}

export function Sidebar({children, className, ...other}) {
  return <div className={classNames('Sidebar', Classes.DARK, className)} {...other}>
    { children }
  </div>
}