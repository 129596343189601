import React from "react";
import {Button, Card, EditableText, H5, Icon, Intent} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

import AnswerParameters from "./AnswerParameters";

import "./ExerciseAnswer.scss";

export default function ExerciseAnswer({
  exerciseType,
  exerciseParameters,
  questionParameters,
  answer,

  setAnswerField$,
  canDeleteAnswer,
  deleteAnswer,
  setAnswerParameter$,

  dragHandleProps
}) {
  return (
    <Card className="ExerciseAnswer">
      <div className="answer-header">
        <H5 className="card-header">
          <Icon className="card-header-drag-icon"
            icon={IconNames.DRAG_HANDLE_VERTICAL}
            iconSize={Icon.SIZE_LARGE}
            {...dragHandleProps}
          />
          <EditableText className="card-header-content"
            placeholder="Treść odpowiedzi"
            multiline

            value={answer['content']}
            onChange={setAnswerField$('content')}
          />
          {canDeleteAnswer &&
          <Button className="card-header-button"
            icon={IconNames.DELETE}
            intent={Intent.DANGER}
            onClick={deleteAnswer}
          />
          }
        </H5>
      </div>
      <AnswerParameters
        exerciseType={exerciseType}
        exerciseParameters={exerciseParameters}
        questionParameters={questionParameters}
        answer={answer}

        setAnswerField$={setAnswerField$}
        setAnswerParameter$={setAnswerParameter$}
      />
    </Card>
  )
}