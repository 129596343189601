export default class Instance {
  static TYPES = {
    PROJECTS_LIST_CHANGED: "PROJECTS_LIST_CHANGED",
    PROJECTS_LIST_ORDER_CHANGED: "PROJECTS_LIST_ORDER_CHANGED",
  };

  static projectsListChanged(projectsList) {
    return {
      type: Instance.TYPES.PROJECTS_LIST_CHANGED,
      projectsList,
    }
  };

  static projectsListOrderChanged(newOrderIds) {
    return {
      type: Instance.TYPES.PROJECTS_LIST_ORDER_CHANGED,
      newOrderIds,
    }
  };
}
