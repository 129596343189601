const ExerciseTypes = [
  {
    id: 1,
    type: 'rise_fall',
    name: 'Balony',
    description: 'Wybierz jedną ze zmieniających się odpowiedzi na wyświetlone pytanie.',
  }, {
    id: 2,
    type: 'tiles',
    name: 'Kafle',
    description: 'Wybierz jedną z odpowiedzi na wyświetlone pytanie.',
  }, {
    id: 3,
    type: 'train',
    name: 'Pociąg',
    description: 'Wybierz, czy wyświetlona odpowiedź jest prawidłową.',
  }, {
    id: 4,
    type: 'shooting',
    name: 'Strzelanka',
    description: 'Zestrzel odpowiedzi, które są prawidłową odpowiedzią na wyświetlone pytanie.',
  }, {
    id: 5,
    type: 'choices',
    name: 'Kafle (bez punktów)',
    description: 'Wybierz kilka odpowiedzi.',
  }, {
    id: 6,
    type: 'message',
    name: 'Wiadomość',
    description: 'Wyświetl wiadomość.',
  }, {
    id: 7,
    type: 'memory',
    name: 'Memory',
    description: 'Odkrywaj pary obrazków.',
  }, {
    id: 8,
    type: 'reveal',
    name: 'Zakryte kafle (wielokrotny wybór)',
    description: 'Odkrywaj pojedyncze odpowiedzi, aby odnaleźć te właściwe.',
  }, {
    id: 9,
    type: 'elevator',
    name: 'Winda',
    description: 'Wysiądź na piętrze z prawidłową odpowiedzią.',
  }, {
    id: 10,
    type: 'doors',
    name: 'Drzwi',
    description: 'Otwórz drzwi kluczem z prawidłową odpowiedzią.',
  }, {
    id: 11,
    type: 'open-question',
    name: 'Otwarte pytanie',
    description: 'Odpowiedz na pytanie otwarte.',
  }, {
    id: 12,
    type: 'match',
    name: 'Dopasowywanie',
    description: 'Dopasowywanie wyświetlonych odpowiedzi do kategorii',
  }, {
    id: 13,
    type: 'parachutes',
    name: 'Spadochrony',
    description: 'Odpowiadanie na pytania spadające na spadochronach',
  }
];



export default ExerciseTypes;