class DefaultInfo {
  static exercise = {
    parameters: [],
    modules: [],
  };

  static question = {
    hasContent: true,
    parameters: [],
  };

  static answer = {
    hasNoCorrectIncorrect: false,
    parameters: [],
    modules: [],
  };

  static canAddQuestion = () => { return true };
  static canAddAnswer = () => { return true };
  static canMoveAnswer = () => { return true };

  static newQuestion = () => (
    {
      content: "",
      parameters: {},
      answers: [
        this.newAnswer()
      ]
    }
  );

  static newAnswer = () => (
    {
      content: "",
      correct: false,
      parameters: {}
    }
  );
}

export default DefaultInfo;