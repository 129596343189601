import React from 'react';
import {Button, ButtonGroup, Card, H5} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

export default function ExerciseClipboard({exerciseId, exerciseName, pasteExercise, ...other}) {

  function Description() {
    if (exerciseId === undefined) {
      return <p>Schowek pusty.</p>
    } else {
      return <p>Skopiowane ćwiczenie: <strong>{exerciseName}</strong></p>
    }
  }

  return <Card {...other}>
    <H5>Schowek</H5>
    <Description/>
    <ButtonGroup fill={true}>
      <Button
        disabled={exerciseId === undefined}
        text="Wklej ćwiczenie do sprintu"
        icon={IconNames.INSERT}
        onClick={pasteExercise}
      />
    </ButtonGroup>
  </Card>
}