import React, {useState, useEffect} from 'react';
import {Card, H5, InputGroup, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

export default function SessionDetails({session, setSessionName, updateSession, ...other}) {
  let component;
  const [formSessionName, setFormSessionName] = useState(session ? session.name : "");

  useEffect(() => {
    if (session) {
      setFormSessionName(session.name);
    }
  }, [session]);

  function sessionNameChanged(sessionName) {
    setFormSessionName(sessionName);
    setSessionName(sessionName);
  }

  function checkForUpdate() {
    if (formSessionName !== session.name) {
      updateSession();
    }
  }

  if (!session) {
    component = <NonIdealState
      icon={IconNames.ZOOM_OUT}
      title="Wybierz sprint, aby wyświetlić informacje o nim."
    />
  } else {
    component = <>
      <H5>Wybrany sprint</H5>
      <InputGroup
        leftIcon={IconNames.LABEL}
        value={formSessionName}
        onChange={e => sessionNameChanged(e.target.value)}
        onBlur={checkForUpdate}
      />
    </>;
  }

  return <Card {...other}>
    {component}
  </Card>
}