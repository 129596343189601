import config from 'react-global-configuration';

export function getConnectionOptions(url, method='GET', data={}) {
  return {
    method: method,
    url: getServerAddress(url),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data: data
  }
}

export function getServerAddress(url) {
  return `${config.get('serverAddress')}/admin/${url}`;
}

export function getPlayServerAddress(slug) {
  return `${config.get('playServerAddress')}/${slug}`;
}