import React from 'react';
import {Card, Elevation, H2} from "@blueprintjs/core";
import OverlayContent from "../../ui/OverlayContent";
import {connect} from "react-redux";
import Project from "../../../actions/project";
import ProjectEditorForm from "./ProjectEditorForm";

function CreateProjectOverlay({slugs, createProject, toggleOverlay}) {

  return (
    <OverlayContent>
      <Card
        elevation={Elevation.TWO}
      >
        <H2>Tworzenie projektu</H2>
        <ProjectEditorForm
          slugs={slugs}
          save={createProject}
          close={toggleOverlay}
          canClose={true}
        />
      </Card>
    </OverlayContent>
  );
}

const mapStateToProps = state => {
  return {
    slugs: state.instance.projectsList.map(project => project["slug"])
  }
};

const mapDispatchToProps = dispatch => {
  return {
    createProject: (projectData) => {
      dispatch(Project.createProject(projectData))
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectOverlay);