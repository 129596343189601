import React from 'react';
import classNames from 'classnames'
import {Button, ButtonGroup, Card, Classes, H4, Icon, Intent, Popover} from "@blueprintjs/core";

import "./ProjectCard.scss";
import {IconNames} from "@blueprintjs/icons";
import ButtonLink from "../../ui/ButtonLink";
import {AdminPaths} from "../../Admin/Admin";

export default function ProjectCard({
  project,
  selectProject, removeProject,
  isSelected, dragHandleProps}) {
  return (
    <Card className={classNames(
      "ProjectCard",
      {
        "selected": isSelected
      },
    )}
      interactive={true}
      onClick={(e) => {
        // To prevent triggering click, when clicked on something on card
        let target = e.target;
        while (target !== e.currentTarget) {
          if (target.onclick !== null) {
            return
          } else {
            target = target.parentElement;
          }
        }

        selectProject()
      }}
    >
      <div className="title">
        <Icon className="drag-handle"
          iconSize={Icon.SIZE_LARGE}
          icon={IconNames.DRAG_HANDLE_VERTICAL}
          {...dragHandleProps}
        />
        <h3 className="name">{project["name"]} <span className="slug">({project["slug"]})</span></h3>
        <ButtonGroup>
          <ButtonLink
            icon={IconNames.EDIT}
            text="Edytuj"

            to={AdminPaths.openProject(project["id"])}

            intent={Intent.PRIMARY}
          />
          <Popover>
            <Button
              icon={IconNames.DELETE}

              intent={Intent.DANGER}
            />
            <>
              <H4>Czy na pewno chcesz usunąć projekt <strong>{project["name"]}</strong>?</H4>
              <ButtonGroup
                fill
              >
                <Button
                  className={Classes.POPOVER_DISMISS}
                  text="Nie"
                />
                <Button
                  text="Tak"
                  intent={Intent.DANGER}

                  onClick={removeProject}
                />
              </ButtonGroup>
            </>
          </Popover>
        </ButtonGroup>
      </div>
      <p className="description">{project["description"]}</p>
    </Card>
  );
}