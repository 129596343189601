import React from 'react';
import {Button, ButtonGroup, Card, H5} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";

export default function SprintClipboard({sprintId, sprintName, pasteSprint, ...other}) {

  function Description() {
    if (sprintId === undefined) {
      return <p>Schowek pusty.</p>
    } else {
      return <p>Skopiowany sprint: <strong>{sprintName}</strong></p>
    }
  }

  return <Card {...other}>
    <H5>Schowek</H5>
    <Description/>
    <ButtonGroup fill={true}>
      <Button
        disabled={sprintId === undefined}
        text="Wklej sprint do projektu"
        icon={IconNames.INSERT}
        onClick={pasteSprint}
      />
    </ButtonGroup>
  </Card>
}