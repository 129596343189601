import AnswerCategoriesModuleInfo from "./modules/AnswerCategoriesModuleInfo";

const INFOS = {
  'answer_categories': AnswerCategoriesModuleInfo,
};

export default class ModuleInfoFactory {
  /**
   * @param type
   * @returns DefaultInfo
   */
  static getInfo(type) {
    const info = INFOS[type];

    if (!info) {
      console.warn(`Incorrect module type: ${type}`)
    } else {
      return info;
    }
  }
}