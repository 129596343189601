import {exerciseWithTime} from "./common/exercise";
import FIELD_TYPES from "../fieldTypes";
import DefaultInfo from "./_default";
import {answerWithFeedback} from "./common/answer";


class ElevatorInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...exerciseWithTime,
      {
        id: "automaticMode",
        type: FIELD_TYPES.bool,
        description: "Automatyczna zmiana piętra?",
        default: false,
        inline: true,
      }
    ],
  };

  static answer = {
    hasNoCorrectIncorrect: false,
    parameters: [
      ...answerWithFeedback,
    ],
  };

  newAnswer = () => (
    {
      content: "",
      correct: false,
      parameters: {
        feedback: "",
        parking: false,
      }
    }
  );
}

export default ElevatorInfo;