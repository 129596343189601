import React from 'react';
import {Card, H5, NonIdealState} from "@blueprintjs/core";
import {IconNames} from "@blueprintjs/icons";
import ProjectEditorForm from "./ProjectEditorForm";
import Project from "../../../actions/project";
import {connect} from "react-redux";

function ProjectDetails({
  project, slugs,
  updateProject
}) {
  let component;

  const innerUpdateProject = (projectData) => {
    if (project !== undefined) {
      updateProject(project["id"], projectData, true);
    }
  };

  if (!project) {
    component = <NonIdealState
      icon={IconNames.ZOOM_OUT}
      title="Wybierz projekt, aby wyświetlić informacje o nim."
    />
  } else {
    component = <>
      <H5>Informacje o projekcie</H5>
      <ProjectEditorForm
        project={project}
        canClose={false}
        isEditing={true}

        save={innerUpdateProject}
        slugs={slugs}
      />
    </>;
  }

  return <Card className="ProjectDetails">
    {component}
  </Card>
}

const mapStateToProps = state => {
  return {
    slugs: state.instance.projectsList.map(project => project["slug"])
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateProject: (projectId, projectData, shouldUpdateProjectsList) => {
      dispatch(Project.updateProject(projectId,projectData,shouldUpdateProjectsList))
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetails);