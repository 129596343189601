const WithModalsComponentMixin = (BaseObject) => class extends BaseObject {
  constructor(props) {
    super(props);

    if (!this.state) {
      this.state = {};
    }

    this.state = Object.assign(this.state, this.state = {
      modals: {}
    });
  }

  toggleModalAction = (type) => () => {
    this.setState((state) => {
      state.modals[type] = !state.modals[type];

      return {
        modals: state.modals,
      }
    })
  };
};

export default WithModalsComponentMixin;