import Global from "./global";
import Instance from "./instance";

export default class Project {
  static TYPES = {
    PROJECT_SELECTED: 'PROJECT_SELECTED',
    PROJECT_CHANGED: 'PROJECT_CHANGED',
    SPRINTS_LIST_CHANGED: 'SPRINTS_LIST_CHANGED',
  };

  static projectSelected(id) {
    return {
      type: Project.TYPES.PROJECT_SELECTED,
      id,
    }
  }

  static projectChanged(projectData) {
    return {
      type: Project.TYPES.PROJECT_CHANGED,
      projectData,
    }
  }

  static sprintsListChanged(sprintsList) {
    return {
      type: Project.TYPES.SPRINTS_LIST_CHANGED,
      sprintsList,
    }
  }

  static loadSprintsList() {
    return instanceRequestWithSprintsListChanges(
      "Wczytuję listę sprintów",
      "Nie mogę wczytać listy sprintów.",
      "Wystąpił problem podczas wczytywania listy sprintów.",
      {
        method: 'GET',
        url: `sessions`,
      }
    );
  }

  static reorderSprintsList(sprintOrder) {
    return instanceRequestWithSprintsListChanges(
      "Zmieniam kolejność sprintów",
      "Nie mogę zapisać kolejności sprintów.",
      "Wystąpił problem podczas zapisywania kolejności sprintów.",
      {
        method: 'POST',
        url: `reorder`,
        data: {
          sessionOrder: sprintOrder
        }
      },
    );
  }

  static createSprint(sprintName) {
    return instanceRequestWithSprintsListChanges(
      "Dodaję sprint",
      "Nie mogę dodać sprintu.",
      "Wystąpił problem podczas dodawania sprintu",
      {
        method: 'POST',
        url: `session`,
        data: {
          sessionName: sprintName
        }
      }
    );
  }

  static removeSprint(sprintId) {
    return instanceRequestWithSprintsListChanges(
      "Usuwam sprint",
      "Nie mogę usunąć sprintu.",
      "Wystąpił problem podczas usuwania sprintu",
      {
        method: 'DELETE',
        url: `session/${sprintId}`,
      }
    );
  }

  static loadProjectsList() {
    return instanceRequestWithProjectsListChanges(
      "Wczytuję listę projektów",
      "Nie mogę wczytać listy projektów.",
      "Wystąpił problem podczas wczytywania listy projektów.",
      {
        url: '',
        method: 'GET',
      },
      {
        shouldUpdateProjectsList: false
      },
    );
  }

  static reorderProjectsList(projectsListOrderIds) {
    return instanceRequestWithProjectsListChanges(
      "Zmieniam kolejność projektów",
      "Nie mogę wczytać zmienić kolejności projektów.",
      "Wystąpił problem podczas zmiany kolejności projektów.",
      {
        url: 'reorder',
        method: 'POST',
        data: {
          projectsListOrderIds,
        }
      },
      {
        preAction: (dispatch) => {
          dispatch(Instance.projectsListOrderChanged(projectsListOrderIds))
        },
        shouldUpdateProjectsList: false
      },
    );
  }

  static createProject(projectData) {
    return instanceRequestWithProjectsListChanges(
      "Tworzę projekt",
      "Nie mogę utworzyć projektu.",
      "Wystąpił problem podczas tworzenia projektu.",
      {
        url: '',
        method: 'POST',
        data: {
          projectData
        }
      },
      {}
    )
  }

  static updateProject(projectId, projectData, shouldUpdateProjectsList=false) {
    return instanceRequestWithProjectsListChanges(
      "Zmieniam projekt",
      "Nie mogę zmienić projektu.",
      "Wystąpił problem podczas zmieniania projektu.",
      {
        url: `${projectId}`,
        method: 'PUT',
        data: {
          projectData
        },
      },
      {
        shouldUpdateProjectsList,
      },
    )
  }

  static removeProject(projectId) {
    return instanceRequestWithProjectsListChanges(
      "Usuwam projekt",
      "Nie mogę usunąć projektu.",
      "Wystąpił problem podczas usuwania projektu.",
      {
        url: `${projectId}`,
        method: 'DELETE',
      },
      {}
    )
  }

  static loadProject(projectId) {
    return instanceRequestWithProjectsListChanges(
      "Wczytuję projekt",
      "Nie mogę wczytać projektu.",
      "Wystąpił problem podczas wczytywania projektu.",
      {
        url: `${projectId}`,
        method: 'GET',
      },
      {
        shouldUpdateProjectsList: false,
      },
    )
  }
}

function instanceRequestWithSprintsListChanges(processingMessage, errorMessage, requestErrorMessage, requestOptions) {
  return async (dispatch, getState) => {
    const {project} = getState();
    const {currentProjectId} = project;

    const closingSlash = requestOptions['url'] ? "/" : "";

    requestOptions['url'] = `admin/instance/${currentProjectId}` + closingSlash + requestOptions['url'];

    try {
      dispatch(Global.startProcessing(processingMessage));
      const {data, status} = await window.axios(requestOptions);
      if (status !== 200) {
        dispatch(Global.errorOccurred(errorMessage));
      } else {
        const {sessions} = data;
        dispatch(Project.sprintsListChanged(sessions));
        dispatch(Global.finishProcessing());
      }
    } catch(error) {
      dispatch(Global.errorOccurred(requestErrorMessage));
    }
  }
}

function instanceRequestWithProjectsListChanges(processingMessage, errorMessage, requestErrorMessage, requestOptions, {
  preAction = (dispatch, getState) => {},
  shouldUpdateProjectsList=true,
}) {
  return async (dispatch, getState) => {
    preAction(dispatch, getState);

    const closingSlash = requestOptions['url'] ? "/" : "";

    requestOptions['url'] = `admin/project` + closingSlash + requestOptions['url'];

    // try {
      dispatch(Global.startProcessing(processingMessage));
      const {data, status} = await window.axios(requestOptions);
      if (status !== 200) {
        dispatch(Global.errorOccurred(errorMessage));
      } else {
        const {project, projects} = data;
        if (projects)
          dispatch(Instance.projectsListChanged(projects));
        if (project)
          dispatch(Project.projectChanged(project));

        dispatch(Global.finishProcessing());

        if (shouldUpdateProjectsList) {
          dispatch(Project.loadProjectsList())
        }
      }
    // }
    // catch(error) {
    //   dispatch(Global.errorOccurred(requestErrorMessage));
    // }
  }
}