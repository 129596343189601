import React, {useState} from 'react';
import {EditableText, FormGroup} from "@blueprintjs/core";
import classNames from 'classnames';

import "./TextInput.scss";

export default function TextInput({
  id, description, helperText, placeholder,
  value, setValue,
  multiline=true, inline=false, small=false
}) {
  const [innerValue, setInnerValue] = useState();
  const valueConfirmed = (newValue) => {
    setValue(innerValue);
    setInnerValue(undefined);
  };

  return (
    <FormGroup
      className={classNames("TextInput", "Input", {"small": small})}
      inline={inline}
      label={description}
      labelFor={id}
      helperText={helperText}
    >
      <EditableText id={id}
        placeholder={placeholder}
        multiline={multiline}

        defaultValue={value}
        value={innerValue}
        onChange={setInnerValue}
        onConfirm={valueConfirmed}
      />
    </FormGroup>
  );
}