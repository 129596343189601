import Auth from "../actions/auth";

const initialState = {
  wasAuthorizationChecked: false,
  loggedIn: false,
  roles: [],
};

export default function auth(state = initialState, action) {
  switch(action.type) {
    case Auth.TYPES.FINISHED_AUTHORIZATION_CHECK:
      const {isAuthorized, roles} = action;
      return {
        ...state,
        loggedIn: isAuthorized,
        wasAuthorizationChecked: true,
        roles,
      };

    case Auth.TYPES.LOGOUT:
      window.localStorage.removeItem('username');
      window.localStorage.removeItem('token');

      return {
        ...initialState,
        wasAuthorizationChecked: true,
      };

    default:
      return state;
  }
};