import Project from "../actions/project";
import {sortById} from "../utilities/redux";

const initialState = {
  currentProjectId: undefined,
  currentProject: undefined,

  initialised: false,
  sprintsList: [],
  sprintsById: {},
};

export default function project(state = initialState, action) {
  switch (action.type) {
    case (Project.TYPES.PROJECT_SELECTED):
      const {id} = action;
      return {
        ...state,
        currentProjectId: id,
      };

    case (Project.TYPES.PROJECT_CHANGED):
      const {projectData} = action;
      return {
        ...state,
        currentProject: projectData,
      };

    case Project.TYPES.SPRINTS_LIST_CHANGED:
      const {sprintsList} = action;
      return {
        ...state,
        initialised: true,
        sprintsList,
        sprintsById: sortById(sprintsList),
      };

    default:
      return state;
  }
}
