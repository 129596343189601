import React from 'react';
import {FormGroup, Switch} from "@blueprintjs/core";
import classNames from 'classnames';

import "./BooleanInput.scss";

export default function BooleanInput({
  id, description, helperText,
  labelChecked, labelUnchecked,
  valueChecked, valueUnchecked,
  value, setValue,
  useTextValues,
  inline=false, small=false}) {

  function getValue() {
    if (useTextValues) {
      return value === valueChecked
    } else {
      return value
    }
  }

  function innerSetValue(isChecked) {
    if (useTextValues) {
      isChecked ? setValue(valueChecked) : setValue(valueUnchecked);
    } else {
      setValue(isChecked)
    }
  }

  return (
    <FormGroup
      className={classNames("BooleanInput", "Input", {"small": small})}
      inline={inline}
      label={description}
      labelFor={id}
      helperText={helperText}
    >
      <Switch id={id}

        innerLabel={labelUnchecked ? labelUnchecked : "Nie"}
        innerLabelChecked={labelChecked ? labelChecked : "Tak"}

        checked={getValue()}
        onChange={e => {innerSetValue(e.target.checked)}}
      />
    </FormGroup>
  );
}