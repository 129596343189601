import axios from "axios";
import config from "react-global-configuration";

config.set({
  // Without trailing slash
  // serverAddress: 'https://phytopharm.s.sprin.tech',
  // serverAddress: 'https://s4.sprintechlearning.com',
  // serverAddress: 'https://zlotetarasy.s.sprin.tech',
  // serverAddress:  'https://kpmg.s.sprin.tech',
  // serverAddress: 'https://dev.s.sprin.tech',
  // serverAddress: 'https://s.sprin.tech',
  serverAddress: 'https://swieta.s.sprin.tech',
  // serverAddress: 'http://192.168.0.4:8888',
  // serverAddress: 'http://localhost:8888',

  // playServerAddress: 'https://play.sprin.tech',
  // playServerAddress: 'https://dev.sprin.tech',
  playServerAddress: 'https://swieta.sprin.tech',
  // playServerAddress: 'https://localhost:3000',
});

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = `${config.get('serverAddress')}/`;
window.axios = axios;